import { Component, OnInit } from '@angular/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { Observable } from 'rxjs';

@Component({
  selector: 'fgb-account-details-page',
  templateUrl: './account-details-page.component.html',
  styleUrls: ['./account-details-page.component.scss'],
})
export class AccountDetailsPageComponent implements OnInit {
  name$: Observable<string>;
  screenType = ScreenType;

  constructor() {}

  ngOnInit() {}
}
