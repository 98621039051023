<nav class="nav nav-secondary account-details-nav">
  <!--Home-->
  <a
    class="nav-item"
    [routerLink]="['/']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'account.nav.home.screenreader' | translate: { fgbdefault: 'Move to home page' } }}"
  >
    <div class="icon material-icons" aria-hidden="true">home</div>
    <label>{{ 'nav.home' | translate }}</label>
  </a>
  <!--Challenges-->
  <a
    class="nav-item"
    [routerLink]="['/challenges']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'account.nav.challenges.screenreader' | translate: { fgbdefault: 'Move to challenges page' } }}"
  >
    <div class="icon material-icons" aria-hidden="true">flag</div>
    <label>{{ 'nav.challenges' | translate }}</label>
  </a>
  <!--Badges-->
  <a
    class="nav-item"
    [routerLink]="['/badges']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'account.nav.badges.screenreader' | translate: { fgbdefault: 'Move to badges page' } }}"
  >
    <div class="icon material-icons" aria-hidden="true">local_police</div>
    <label>{{ 'account.nav.badges.text' | translate }}</label>
  </a>

  <!--Wallet-->
  <a
    class="nav-item"
    [routerLink]="['/rewards/wallet']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'account.nav.wallet.screenreader' | translate: { fgbdefault: 'Move to wallet page' } }}"
  >
    <div class="icon material-icons" aria-hidden="true">account_balance_wallet</div>
    <label>{{ 'nav.wallet' | translate }}</label>
  </a>
</nav>
