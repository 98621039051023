<div class="d-none d-lg-block">
  <div class="banner" role="banner">
    <h1 *ngIf="marketplaceItem$ | async">{{ marketplaceItemType.group }}</h1>
  </div>
  <fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>
</div>

<!-- Points Summary -->
<div class="container">
  <fgb-points-summary-bar
    [backButtonLink]="marketplaceItemType.backLink"
    [marketplaceItemGroup]="marketplaceItemType.group"
  ></fgb-points-summary-bar>
</div>

<fgbcl-marketplace-details [itemId]="id" [actionLabels]="actionLabels" [remainingStockOnly]="false"></fgbcl-marketplace-details>

<div class="container">
  <div class="text-primary pt-3 cursor-pointer d-none d-lg-block" [routerLink]="[marketplaceItemType.backLink]">
    <span class="icon icon-arrow-1-left font-weight-bold pr-2 font-size-10"></span>
    <span class="font-size-14 font-weight-bold">Back to {{ marketplaceItemType.group }}</span>
  </div>
</div>
