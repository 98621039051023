<ng-container *ifScreenSize="screenType.Desktop">
  <div class="banner" role="banner"></div>
</ng-container>

<ng-container *ifScreenSize="screenType.Mobile">
  <div class="container py-3">
    <fgb-barcode></fgb-barcode>
  </div>
</ng-container>

<!--Account Nav-->
<fgb-account-nav></fgb-account-nav>

<div class="container text-center">
  <!-- Cards -->
  <ng-container *ifScreenSize="screenType.Desktop">
    <div class="row mt-4">
      <div class="col-lg-6">
        <fgb-member-card></fgb-member-card>
      </div>
      <div class="col-lg-6">
        <img alt="" class="widget rounded" src="assets/images/placeholder-widget.jpg" draggable="false" />
      </div>
    </div>
  </ng-container>
</div>

<!--Scorecard carousel-->

<div class="container">
  <h5 class="feature-challenges-heading mt-4">{{ 'badge.featured.challenges' | translate }}</h5>
</div>

<div class="mt-2">
  <fgbcl-scorecard-list-view [icon]="'chevron_right'" [version2Design]="true" [phaseThree]="true"></fgbcl-scorecard-list-view>
</div>

<div class="container">
  <!-- Announcements -->
  <fgbcl-announcements></fgbcl-announcements>
</div>

<!-- Campaign Offers -->
<fgbcl-campaigns-offers-list [showAllOffers]="true"></fgbcl-campaigns-offers-list>

<!-- TO BE USED FOR TESTING SCORECARDS -->
<ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.voucher'">
  <fgb-vouchers></fgb-vouchers>
</ng-container>
