<div class="barcode-component w-100 d-block d-lg-none border rounded">
  <div class="bg-white border-0" *ngIf="barcode$ | async as barcode">
    <div class="p-0 flex-column align-items-center d-flex flex-wrap justify-content-around mt-3">
      <div class="member-details w-100 rounded-0 text-center">
        <div class="font-weight-bold card-name" *ngIf="memberDetails$ | async as memberDetails">
          {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
        </div>
        <ng-container *ngIf="card$ | async as memberCard">
          <div class="text-black font-size-10">
            <div>
              {{ memberCard.ProductCode }} {{ 'product.code.description' | translate }} - {{ memberCard.ExternalRefNumber }}
            </div>
          </div>
        </ng-container>
      </div>
      <!--Barcode-->
      <div class="qr py-0 bg-white">
        <qrcode [qrdata]="barcode.Barcode" [size]="84" [level]="'M'" [usesvg]="true"></qrcode>
      </div>
      <!--Member Details-->
      <div
        class="discount-details pr-2 w-100 rounded-0"
        [ngClass]="season ? 'bg-primary text-black' : 'bg-secondary text-primary'"
      >
        <div class="mb-1 member-card-footer pt-2">
          <ng-container *ngIf="card$ | async as memberCard">
            <div class="d-flex align-items-center">
              <div class="text-center pr-4">
                <label class="font-size-14 mb-1">{{ memberCard.RetailDiscountLevel }}%</label>
                <div class="font-size-12">{{ 'member.card.retail.discount' | translate }}</div>
              </div>

              <div [ngClass]="season ? 'border-right-season' : 'border-right-premium'"></div>

              <div class="text-center pl-4">
                <label class="font-size-14 mb-1">{{ memberCard.DiscountLevel }}%</label>
                <div class="font-size-12">{{ 'member.card.discount' | translate }}</div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
