import { Component, OnInit } from '@angular/core';
import { config, AnnouncementService } from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { Observable } from 'rxjs';
@Component({
  selector: 'fgb-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  config = config;
  screenType = ScreenType;
  name$: Observable<string>;

  constructor(private announcementService: AnnouncementService) {}

  ngOnInit() {
    this.announcementService.fetchAnnouncements().toPromise();
  }
}
