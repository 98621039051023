import { Component, OnInit } from '@angular/core';
// import { Router } from '@angular/router';
// import { AuthService } from '@fgb/core';
import { ErrorStateService } from '@fgb/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'fgb-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.scss'],
})
export class MaintenancePageComponent implements OnInit {
  constructor(
    // private router: Router,
    public errorService: ErrorStateService,
    // private authService: AuthService,
    public deviceDetector: DeviceDetectorService
  ) {
    // if (this.authService.checkMaintenceMode()) {
    //   if (this.authService.hasCurrentRefreshToken()) {
    //     this.router.navigate(['/']);
    //   } else {
    //     this.router.navigate(['login']);
    //   }
    // }
  }

  ngOnInit() {}
}
