<div class="full-page w-100 d-md-flex d-block px-0">
  <!--Left Side-->
  <img class="shared-login-banner d-md-block d-none" draggable="false" src="assets/images/login-registration/login-banner.jpg" />
  <div class="bg-primary d-flex w-100 d-md-none m-auto mobile-header"></div>
  <!--Right Side-->
  <div class="right-side-login d-flex flex-column justify-content-center align-items-center">
    <img alt="" class="logo px-2" src="assets/images/logo-words.png" draggable="false" />
    <div class="panel-text login-text font-size-14 text-center">
      {{ 'login.page.description' | translate }}
    </div>
    <fgb-tm-user-login></fgb-tm-user-login>

    <ng-container *ngIf="errorCode$ | async as errorCode">
      <div class="login-text-info text-black text-center mt-5 pb-5" [innerHTML]="errorCode | translate | markdownTranslate"></div>
    </ng-container>
  </div>
</div>
