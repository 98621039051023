<h3 *ngIf="ecashView" class="text-black mb-4 d-lg-block d-none">
  {{ 'payment.history.page.title' | translate }}
</h3>

<div class="card border-0 mt-3 mt-lg-0">
  <div [ngClass]="{ 'card-body-ecash': ecashView, 'card-body-account': accountView }">
    <div class="d-flex flex-column flex-lg-row justify-content-lg-between">
      <fgbcl-date-range-picker [(startDate)]="transactionStartDate" [(endDate)]="transactionEndDate"></fgbcl-date-range-picker>
      <button type="button" class="search-button font-weight-bold mb-2 d-block border-0 text-white bg-black" (click)="search()">
        Search
      </button>
    </div>
  </div>
</div>

<fgb-transaction-list
  *ngIf="selectedValues; else noSearch"
  [startDate]="selectedValues.start"
  [endDate]="selectedValues.end"
  [memberId]="memberId"
  [transactionType]="selectedValues.type"
></fgb-transaction-list>

<ng-template #noSearch>
  <div class="d-flex flex-column">
    <span class="icon material-icons material-history-icon mx-auto text-grey mt-5 mb-3">history</span>
    <h5 *ngIf="ecashView" class="text-center mb-0 text-uppercase font-size-16 text-primary">
      {{ 'account.transactions.placeholder.list.title' | translate }}
    </h5>
    <h5 *ngIf="accountView" class="text-center mb-0 text-uppercase font-size-16 text-primary">
      {{ 'account.activity.placeholder.list.title' | translate }}
    </h5>
    <p class="text-center font-size-12 text-grey">{{ 'account.activity.placeholder.list.description' | translate }}</p>
  </div>
</ng-template>
