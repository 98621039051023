<div class="card rounded border">
  <div *ngIf="member$ | async as member">
    <div class="">
      <ng-container *ngIf="card$ | async as card">
        <label class="mb-0">{{ 'account.details.membership.number' | translate }}</label>
        <div class="d-flex pb-3 m-0">
          <span>{{ card.MemberSince }}</span>
        </div>
      </ng-container>
      <label class="mb-0">{{ 'account.details.dob' | translate }}</label>
      <div class="d-flex bg-muted p-2 m-0 mt-1 rounded">
        <span class="icon material-icons" aria-hidden="true">date_range</span>
        <span class="ml-3">{{ member.DOB | date: 'MMM d, yyy' }}</span>
      </div>
    </div>
    <div>
      <label class="mb-0 personal-info">{{ 'account.details.email' | translate }}</label>
      <div class="d-flex bg-muted p-2 m-0 mt-1 rounded">
        <span class="icon material-icons" aria-hidden="true">email</span>
        <span class="ml-3">{{ member.EmailAddress1 }}</span>
      </div>
    </div>
  </div>
</div>
