<div class="d-flex d-lg-none bg-secondary mobile-banner justify-content-center align-items-center">
  <img src="assets/images/logo.png" alt="" draggable="false" />
</div>
<div class="full-page login-banner container-fluid w-100">
  <div class="row">
    <div class="col-12">
      <div class="row vh-100">
        <div class="col-lg-6 d-none d-lg-block login-banner"></div>
        <div class="user-login-panel col-lg-6 col-12 text-left bg-white">
          <div class="container h-100">
            <div class="row h-100">
              <div class="col-12">
                <router-outlet></router-outlet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
