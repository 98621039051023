<div class="mobile-nav-menu d-flex position-fixed flex-column d-lg-none">
  <!-- NAV BAR -->
  <div class="mobile-nav-bar d-flex justify-content-between">
    <a class="nav-item font-weight-bold brand p-1" [routerLink]="['/']" routerLinkActive="router-link-active">
      <label id="homeLink" class="accessibility-only">Go To Home Page</label>
      <img alt="" class="home-logo" src="assets/images/logo.png" draggable="false" />
    </a>

    <button
      attr.aria-label="{{ 'nav.toggle.screenreader' | translate }}"
      class="nav-item font-weight-bold navbar-toggler m-0 px-2"
      (click)="isCollapsed = !isCollapsed"
    >
      <span class="icon material-icons material-mobile-nav">menu</span>
    </button>
  </div>

  <!-- NAV SCREEN -->
  <div [ngbCollapse]="isCollapsed" class="mobile-nav-menu-dropdown">
    <div class="d-flex flex-column p-1 mobile-nav-menu-content">
      <!-- Close Button -->
      <button
        class="close-button position-absolute border-0"
        attr.aria-label="{{ 'modal.close' | translate }}"
        (click)="isCollapsed = !isCollapsed"
      >
        <span class="icon font-weight-bold icon-cancel-circle"></span>
      </button>
      <div>
        <img alt="" class="logo px-2" src="assets/images/logo.png" draggable="false" />
      </div>

      <!--Member Details-->
      <div class="container mt-3">
        <ng-container *ngIf="memberCard$ | async as memberCard; else loadingRef">
          <div class="d-flex flex-column justify-content-between h-100 text-white">
            <!--Name and ID-->
            <div class="card-id text-left">
              <p class="mb-0 name-details" *ngIf="memberDetails$ | async as memberDetails">
                {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
              </p>
              <div class="d-flex font-size-12">
                <div>{{ memberCard.ProductCode }} - {{ memberCard.ExternalRefNumber }}</div>
              </div>
              <hr />
            </div>

            <!--Discounts-->
            <div class="d-flex align-items-center">
              <div class="card-points text-left pr-4">
                <label class="font-size-14 mb-1">{{ memberCard.RetailDiscountLevel }}%</label>
                <div class="font-size-12">{{ 'member.card.retail.discount' | translate }}</div>
              </div>

              <div style="border-right: 1px solid #fff; height: 30px"></div>

              <div class="card-id text-left pl-4">
                <label class="font-size-14 mb-1">{{ memberCard.DiscountLevel }}%</label>
                <div class="font-size-12">{{ 'member.card.discount' | translate }}</div>
              </div>
            </div>
          </div>
          <hr />
        </ng-container>
      </div>

      <ng-template #loadingRef>
        <fgbcl-loading></fgbcl-loading>
      </ng-template>
      <!--End of Member Details-->

      <!-- Nav Buttons -->
      <div class="mobile-nav-button-container">
        <a
          class="nav-item font-weight-bold large-item"
          [routerLink]="['/account']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="nav-item-title-div">
            <span class="icon material-icons mr-2" aria-hidden="true">person</span>
            <div class="nav-text font-weight-bold">{{ 'nav.account' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a
          class="nav-item"
          [routerLink]="['/rewards/wallet']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="nav-item-title-div">
            <span class="icon material-icons mr-2" aria-hidden="true">account_balance_wallet</span>
            <div class="nav-text font-weight-bold">{{ 'nav.wallet' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a
          class="nav-item"
          [routerLink]="['/challenges']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="nav-item-title-div">
            <span class="icon material-icons mr-2" aria-hidden="true">flag</span>
            <div class="nav-text font-weight-bold">{{ 'nav.challenges' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a class="nav-item" [routerLink]="['/badges']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="nav-item-title-div">
            <span class="icon material-icons mr-2" aria-hidden="true">local_police</span>
            <div class="nav-text font-weight-bold">{{ 'account.nav.badges.text' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a class="nav-item" [routerLink]="['/terms']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="nav-item-title-div">
            <span class="icon material-icons mr-2" aria-hidden="true">article</span>
            <div class="nav-text font-weight-bold">{{ 'nav.terms' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a class="nav-item" [routerLink]="['/faq']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="nav-item-title-div">
            <span class="icon material-icons mr-2" aria-hidden="true">help</span>
            <div class="nav-text font-weight-bold">{{ 'nav.faq' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a class="nav-item logout-link-div" (click)="logout()">
          <span class="icon material-icons mr-2" aria-hidden="true">power_settings_new</span>
          <div class="nav-text font-weight-bold">{{ 'nav.log.out' | translate }}</div>
        </a>
      </div>
    </div>
  </div>
</div>
