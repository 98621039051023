<nav class="nav nav-secondary mixed-loyalty-nav my-0">
  <a  attr.aria-label="{{ 'rewards.nav.featured.screenreader' | translate }}" class="nav-item" [routerLink]="['/rewards']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
    <div class="icon material-icons" aria-hidden="true">star</div>
    <label
      >{{ 'rewards.nav.featured' | translate }}
      <span class="d-none d-lg-inline-block">{{ 'rewards.nav.rewards' | translate: { fgbdefault: 'Move to Featured Rewards page' } }}</span></label
    >
  </a>
  <a
    class="nav-item"
    [routerLink]="['/rewards/marketplace']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'rewards.nav.marketplace.screenreader' | translate: { fgbdefault: 'Move to Marketplace page' } }}"
  >
    <fgbcl-loyalty-icon [loyaltyType]="'marketplace'"></fgbcl-loyalty-icon>
    <label>{{ 'rewards.nav.marketplace' | translate }}</label>
  </a>
  <a
    class="nav-item"
    [routerLink]="['/rewards/auctions']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'rewards.nav.auctions.screenreader' | translate: { fgbdefault: 'Move to Auctions page' } }}"
  >
    <fgbcl-loyalty-icon [loyaltyType]="'auction'"></fgbcl-loyalty-icon>
    <label>{{ 'rewards.nav.auctions' | translate }}</label>
  </a>
  <a
    class="nav-item"
    [routerLink]="['/rewards/raffles']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'rewards.nav.raffles.screenreader' | translate: { fgbdefault: 'Move to Raffles page' } }}"
  >
    <fgbcl-loyalty-icon [loyaltyType]="'lotto'"></fgbcl-loyalty-icon>
    <label>{{ 'rewards.nav.lotto' | translate }}</label>
  </a>
  <a
    class="nav-item"
    [routerLink]="['/rewards/events']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'rewards.nav.events.screenreader' | translate }}"
  >
    <fgbcl-loyalty-icon [loyaltyType]="3"></fgbcl-loyalty-icon>
    <label>{{ 'rewards.nav.events' | translate: { fgbdefault: 'Move to Events page' } }}</label>
  </a>
  <a
    class="nav-item"
    [routerLink]="['/rewards/products']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'rewards.nav.products.screenreader' | translate: { fgbdefault: 'Move to Products page' } }}"
  >
    <fgbcl-loyalty-icon [loyaltyType]="4"></fgbcl-loyalty-icon>
    <label>{{ 'rewards.nav.products' | translate }}</label>
  </a>
  <a
    class="nav-item d-none d-lg-block"
    [routerLink]="['/rewards/wallet']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'rewards.nav.wallet.screenreader' | translate: { fgbdefault: 'Move to Wallet page' } }}"
  >
    <fgbcl-loyalty-icon [loyaltyType]="'wallet'"></fgbcl-loyalty-icon>
    <label>{{ 'rewards.nav.wallet' | translate }}</label>
  </a>
</nav>
