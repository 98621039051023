<div class="d-none d-lg-block">
  <div class="banner" role="banner"></div>
</div>

<ng-container *ifScreenSize="screenType.Mobile">
  <div class="container py-3">
    <fgb-barcode></fgb-barcode>
  </div>
</ng-container>

<!--Account Nav-->
<fgb-account-nav></fgb-account-nav>

<!-- Offers -->
<fgbcl-campaigns-offers-list [showAllOffers]="false"></fgbcl-campaigns-offers-list>

<!-- V2 Wallet -->
<ng-container
  *ngIf="
    {
      walletItemsUnclaimed: walletItemsUnclaimed$ | async
    } as walletItems;
    else noWalletItems
  "
>
  <div class="wallet-redeem-carousel mt-4">
    <div class="container">
      <ng-container *ngIf="walletItems.walletItemsUnclaimed && walletItems.walletItemsUnclaimed.length; else noWalletItems">
        <fgbcl-rewards-wallet-redeem-list [unRedeemedItems]="walletItems.walletItemsUnclaimed"></fgbcl-rewards-wallet-redeem-list>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #noWalletItems>
  <div class="container">
    <h5 class="no-wallet-items mb-3 font-weight-bold">{{ 'rewards.wallet.no.items' | translate }}</h5>
    <div class="border">
      <div class="no-wallet-items-description font-weight-bold font-size-12 text-center text-grey py-3">
        {{ 'rewards.wallet.no.items.description' | translate }}
      </div>
    </div>
  </div>
</ng-template>

<div class="container mt-3 text-center">
  <a class="font-size-14 claimed-text font-weight-bold" [routerLink]="['/rewards/claimed']">
    {{ 'rewards.wallet.view.claimed' | translate }}
  </a>
</div>
