<ng-container *ngIf="memberCard$ | async as memberCard; else loadingRef">
  <h2 class="accessibility-only">Member Card</h2>
  <div class="member-card bg-primary" [ngClass]="season ? 'bg-primary' : 'bg-secondary'">
    <div class="d-flex flex-column justify-content-between h-100">
      <!--Name and ID-->
      <div class="card-id text-left" [ngClass]="season ? 'text-black' : 'text-primary'">
        <p class="mb-0 card-name font-size-18 text-uppercase" *ngIf="memberDetails$ | async as memberDetails">
          {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
        </p>
        <div class="d-flex font-size-12">
          <div>
            {{ memberCard.ProductCode }} {{ 'product.code.description' | translate }} - {{ memberCard.ExternalRefNumber }}
          </div>
        </div>
      </div>
      <!--Discounts-->
      <div class="d-flex align-items-center" [ngClass]="season ? 'text-black' : 'text-primary'">
        <div class="text-left pr-4">
          <label class="font-size-14 mb-0">{{ memberCard.RetailDiscountLevel }}%</label>
          <div class="font-size-12">{{ 'member.card.retail.discount' | translate }}</div>
        </div>

        <div [ngClass]="season ? 'border-right-season' : 'border-right-premium'"></div>

        <div class="text-left pl-4">
          <label class="font-size-14 mb-0">{{ memberCard.DiscountLevel }}%</label>
          <div class="font-size-12">{{ 'member.card.discount' | translate }}</div>
        </div>

        <!--Logo-->
        <img alt="" class="logo px-2" src="assets/images/logo.png" draggable="false" />
      </div>
    </div>
    <!-- </div> -->
  </div>
</ng-container>

<ng-template #loadingRef>
  <fgbcl-loading></fgbcl-loading>
</ng-template>
