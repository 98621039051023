<div role="contentinfo" class="footer text-white text-center bg-secondary">
  <div class="footer-quicklinks-mobile bg-secondary w-100 d-block d-lg-none"></div>
  <div class="footer-text bg-secondary text-white d-flex align-items-center justify-content-center w-100">
    <div class="footer-content mt-5 d-none d-lg-block">
      <img alt="" class="footer-logo mb-2" src="assets/images/logo.svg" draggable="false" />
      <p class="font-size-12 mt-2 text-left text-lg-center" [innerHTML]="'nav.club.rights' | translate | markdownTranslate"></p>
    </div>
    <div class="footer-content mt-2 d-block d-lg-none">
      <img alt="" class="footer-logo mb-2" src="assets/images/logo.png" draggable="false" />
      <p class="text-white font-size-12 pl-3 text-center" [innerHTML]="'nav.club.rights' | translate | markdownTranslate"></p>
    </div>
  </div>
</div>
