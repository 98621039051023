<div class="points-summary-bar d-flex justify-content-between d-lg-none my-2">
  <!-- Back Button -->
  <div class="text-primary cursor-pointer d-flex align-items-center d-lg-none" [routerLink]="[backButtonLink]">
    <span class="icon icon-arrow-1-left font-weight-bold pr-2 font-size-10"></span>
    <span *ngIf="marketplaceItemGroup" class="font-size-14 font-weight-bold">Back to {{ marketplaceItemGroup }}</span>
    <span *ngIf="!marketplaceItemGroup" class="font-size-14 font-weight-bold">Back to {{ backButtonTitle }}</span>
  </div>
  <!-- Points Bar -->
  <div class="justify-content-center flex-grow-1 flex-shrink-1 h-100">
    <fgb-points-summary></fgb-points-summary>
  </div>
</div>
