<h1 class="accessibility-only">Badges</h1>

<ng-container *ifScreenSize="screenType.Desktop">
  <div class="banner" role="banner"></div>
</ng-container>

<ng-container *ifScreenSize="screenType.Mobile">
  <div class="container py-3">
    <fgb-barcode></fgb-barcode>
  </div>
</ng-container>

<!--Account Nav-->
<fgb-account-nav></fgb-account-nav>

<!--Scorecard carousel-->
<div class="mt-4">
  <fgbcl-scorecard-list-view
    [icon]="'chevron_right'"
    [version2Design]="true"
    [phaseThree]="true"
    [showPlaceholder]="true"
  ></fgbcl-scorecard-list-view>
</div>
