<nav class="navbar bg-secondary navbar-dark navbar-expand-lg d-none d-lg-flex shadow">
  <a
    class="navbar-brand py-0"
    [routerLink]="['/']"
    routerLinkActive="router-link-active"
    attr.aria-label="{{ 'nav.home.page.screenreader' | translate: { fgbdefault: 'Move to home page' } }}"
  >
    <label id="homeLink" class="accessibility-only">Go To Home Page</label>
    <img alt="" class="ml-1 h-100" aria-labelledby="homeLink" src="assets/images/logo.png" draggable="false" />
  </a>

  <button
    class="navbar-toggler"
    type="button"
    (click)="isCollapsed = !isCollapsed"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    attr.aria-label="{{ 'nav.toggle.screenreader' | translate }}"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <!-- Left Side Content -->
  <div class="navbar-collapse" [ngbCollapse]="isCollapsed" id="navbarSupportedContent">
    <div class="nav-container">
      <ul class="navbar-nav mr-auto" #greedyNav>
        <li class="nav-item active">
          <a
            class="nav-link mx-1"
            [routerLink]="['/badges']"
            routerLinkActive="router-link-active"
            attr.aria-label="{{ 'nav.badges.screenreader' | translate: { fgbdefault: 'Move to challenges page' } }}"
            >{{ 'nav.challenges' | translate }}</a
          >
        </li>

        <li class="nav-item active">
          <a class="nav-link mx-1" [routerLink]="['/faq']" routerLinkActive="router-link-active">
            {{ 'nav.faq' | translate }}
          </a>
        </li>

        <li class="nav-item active">
          <a class="nav-link mx-1" [routerLink]="['/terms']" routerLinkActive="router-link-active">
            {{ 'nav.terms' | translate }}
          </a>
        </li>
      </ul>
    </div>
    <!-- Right Side Content -->
    <div class="form-inline my-2 my-lg-0">
      <div class="member" ngbDropdown #ddAccount="ngbDropdown" [autoClose]="'outside'" focusTab>
        <button
          class="nav-link btn d-flex align-items-center mx-2 px-0 justify-content-end text-white"
          id="namedropdown"
          ngbDropdownToggle
          attr.aria-label="{{ 'nav.account.dropdown.screenreader' | translate: { fgbdefault: 'Click for more account menu' } }}"
          tabindex="-1"
        >
          <div *ngIf="memberDetails$ | async as memberDetails; else memberDetailsLoading" class="user-box h-100 d-inline-block">
            <div class="font-size-14 text-left text-white">{{ memberDetails!.FirstName }}</div>
          </div>
        </button>

        <div
          ngbDropdownMenu
          (click)="closeDropDown(ddAccount)"
          aria-labelledby="namedropdown"
          class="shadow text-center border-0 dropdown-menu p-0 account-dropdown mt-2"
        >
          <div class="text-info">
            <a
              [routerLink]="['/account']"
              routerLinkActive="router-link-active"
              attr.aria-label="{{ 'nav.account.screenreader' | translate: { fgbdefault: 'Move to account page' } }}"
              class="
                d-flex
                align-items-center
                account-btn
                text-black
                bg-white
                py-0
                px-2
                font-size-14
                nav-item-fixed-height
                rounded-top
              "
              ngbDropdownItem
            >
              <span class="icon material-icons material-dropdown-icon mr-2" aria-hidden="true">person</span>
              <div class="dropdown-link text-left">
                <div class="mt-2 font-weight-bold">{{ 'nav.account' | translate }}</div>
                <div class="nav-bar-little-text text-left" *ngIf="card$ | async as memberCard">
                  {{ memberCard.ProductCode }}
                </div>
              </div>
            </a>
          </div>

          <hr class="text-muted w-75 my-0" />

          <ng-container *ngIf="cards$ | async as cards">
            <div *ngIf="cards.length > 1">
              <div class="text-secondary ml-3">
                <div class="d-flex align-items-center account-btn bg-white py-0 px-2 nav-item-fixed-height" ngbDropdownItem>
                  <div class="icon icon-user-male-pictures mr-2 font-size-16 text-primary"></div>
                  <div class="dropdown-link mt-1 text-left">
                    <div class="font-size-14 text-black font-weight-bold">Switch Account</div>
                  </div>
                </div>
              </div>
              <div class="select-account-container">
                <ng-container *ngFor="let card of cards">
                  <hr class="text-muted w-75 my-0" />
                  <div class="text-secondary">
                    <button
                      (click)="loginWithCard(card.MemberId)"
                      class="
                        d-flex
                        align-items-center
                        account-btn
                        text-secondary
                        bg-white
                        py-0
                        px-2
                        nav-item-fixed-height
                        switch-accounts
                      "
                      ngbDropdownItem
                      attr.aria-label="{{ 'nav.account.expand.login.screenreader' | translate }}"
                    >
                      <div class="ten-game-club" [ngClass]="{ 'border-0': card.MemberId == selectedMember }">
                        <span class="icon icon-check" *ngIf="card.MemberId == selectedMember"></span>
                      </div>
                      <div class="dropdown-link mt-1 text-left">
                        <div class="font-size-14">{{ card.Name }}</div>
                        <div class="font-size-12 text-info">Member ID {{ card.MemberId }}</div>
                      </div>
                    </button>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>

          <hr class="text-muted w-75 my-0" />

          <div class="text-black">
            <a
              [routerLink]="['/rewards/wallet']"
              routerLinkActive="router-link-active"
              attr.aria-label="{{ 'nav.wallet.screenreader' | translate: { fgbdefault: 'Move to wallet page' } }}"
              class="d-flex align-items-center account-btn text-black bg-white py-0 px-2 nav-item-fixed-height"
              ngbDropdownItem
            >
              <span class="icon material-icons material-dropdown-icon mr-2" aria-hidden="true">account_balance_wallet</span>
              <div class="dropdown-link text-left">
                <div class="font-size-14 font-weight-bold">{{ 'nav.wallet' | translate }}</div>
              </div>
            </a>
          </div>
          <hr class="text-muted w-75 my-0" />
          <div>
            <button
              class="logout-btn d-flex align-items-center bg-white px-2 nav-item-fixed-height rounded-bottom"
              (click)="logout()"
              ngbDropdownItem
              attr.aria-label="{{ 'nav.log.out.screenreader' | translate: { fgbdefault: 'Log out' } }}"
            >
              <span class="icon material-icons material-dropdown-icon mr-2 text-danger" aria-hidden="true"
                >power_settings_new</span
              >
              <div class="dropdown-link text-left">
                <div class="text-danger py-1 font-size-14 font-weight-bold" ngbDropdownItem (click)="logout()">
                  {{ 'nav.log.out' | translate: { fgbdefault: 'Log out' } }}
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<ng-template #memberDetailsLoading>
  <div class="user-box d-block text-center text-on-primary px-4">
    <fgbcl-loading [size]="'small'"></fgbcl-loading>
  </div>
</ng-template>
