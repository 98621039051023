import { Component, OnInit, Input } from '@angular/core';
import { MemberCard, MemberCardQuery, MemberDetails, Balance, MemberQuery, ProductCodeQuery } from '@fgb/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'fgb-member-card',
  templateUrl: './member-card.component.html',
  styleUrls: ['./member-card.component.scss'],
})
export class MemberCardComponent implements OnInit {
  @Input() showSeating: boolean;
  memberCard$: Observable<MemberCard | undefined>;
  ecash$: Observable<number | undefined>;
  memberDetails$: Observable<MemberDetails | undefined>;
  balance$: Observable<Balance>;
  member$: Observable<MemberDetails | undefined>;
  pointsBalance$: Observable<number | undefined>;
  combinedCard$: Observable<number | undefined>;
  season: boolean;

  constructor(
    private memberCardQuery: MemberCardQuery,
    private memberQuery: MemberQuery,
    private productCodeQuery: ProductCodeQuery
  ) {}

  ngOnInit() {
    this.memberCard$ = this.memberCardQuery.selectCurrentCard();
    this.memberDetails$ = this.memberQuery.selectMemberDetails();
    this.season = this.productCodeQuery.currentUserIsProductCode(['Season']);
  }
}
